import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { StockAndProductPriceInformationDto } from '@shared/service-proxies/service-proxies';
import { Observable, Subject } from 'rxjs';



@Injectable()
export class NotificationService implements OnDestroy{
    private hubConnection: HubConnection;
    private updateProductCacheSubject = new Subject<boolean>();
    private updateProductPricesSubject = new Subject<string>();
    private downloadnProductsReportSubject = new Subject<any>();
    private downloadnSellerMonthlyReportSubject = new Subject<any>();
    private downloadProductInfoReportSubject = new Subject<any>();
    private stockInformationSubject = new Subject<StockAndProductPriceInformationDto>();
    private reconnectInterval: number = 5000; // 5 seconds
    private isManuallyDisconnected: boolean = false; // Nueva bandera

    updateProductCache$ = this.updateProductCacheSubject.asObservable();
    updateProductPrices$ = this.updateProductPricesSubject.asObservable();
    donwloadProductReport$ = this.downloadnProductsReportSubject.asObservable();
    downloadnSellerMonthly$ = this.downloadnSellerMonthlyReportSubject.asObservable();
    stockListInformation$ = this.stockInformationSubject.asObservable();
    downloadProductInfoReport$ = this.downloadProductInfoReportSubject.asObservable();

    private connectionPromise: Promise<void> | null = null;

    constructor() {
    }

    ngOnDestroy(): void {
        this.disconnect();
    }

    private ensureConnected(): Promise<void> {
        if (!this.hubConnection || this.hubConnection.state !== HubConnectionState.Connected) {
          if (!this.connectionPromise) {
            this.connectionPromise = new Promise((resolve, reject) => {
              this.connect(resolve, reject);
            });
          }
          return this.connectionPromise;
        }
        return Promise.resolve();
      }

    private connect(resolve: () => void, reject: (err: any) => void): void {
        SignalRAspNetCoreHelper.initSignalR(() => {
            abp.signalr.startConnection('/signalr-notificationHub', (connection) => {
                this.hubConnection = connection;
                this.registerOnServerEvents();
                this.hubConnection.onclose(() => {
                    if (!this.isManuallyDisconnected) {
                      this.reconnect();
                    }
                  });
                  resolve();
            }
            ).catch(err => {
                console.error('Error while starting SignalR connection: ', err);
                setTimeout(() => this.connect(resolve, reject), this.reconnectInterval);
            });
        });
    }

    private reconnect(): void {
        console.log('Conexión perdida. Intentando reconectar...');
        this.connectionPromise = new Promise((resolve, reject) => {
        setTimeout(() => this.connect(resolve, reject), this.reconnectInterval);
        });
    }

    private registerOnServerEvents(): void {
        // Connect to Update Products Cache
        this.hubConnection.on('UpdateProductCache', (message: boolean) => {
            this.updateProductCacheSubject.next(message);
        });

        // Conect to update product Prices
        this.hubConnection.on('updateProductPrices', (message: string) => {
            this.updateProductPricesSubject.next(message);
        });

        // Connet to download product report
        this.hubConnection.on("DownloadProductsReport", (excelData: any) => {
            this.downloadnProductsReportSubject.next(excelData);
        });

        // Connect to download seller monthly report
        this.hubConnection.on("DownloadSellerMonthlyReport", (excelData: any) => {
            this.downloadnSellerMonthlyReportSubject.next(excelData);
        });
        
        this.hubConnection.on("DownloadExcelProductInformation", (excelData: any) => {
            this.downloadProductInfoReportSubject.next(excelData);
        });
    }

    disconnect(): void {
        this.isManuallyDisconnected = true; // Marca la desconexión manual
        if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
          this.hubConnection.stop()
            .then(() => {
              console.log('Desconectado del hub de SignalR');
            })
            .catch(err => console.error('Error al desconectar la conexión de SignalR: ', err));

            this.connectionPromise = null;
        }
    }

    onUpdateProductCache(): Observable<boolean> {
        this.ensureConnected();
        return this.updateProductCache$;
    }

    onUpdateProductPrices(): Observable<string> {
        this.ensureConnected();
        return this.updateProductPrices$;
    }

    onDownloadProductsReport(): Observable<any> {
        this.ensureConnected();
        return this.donwloadProductReport$;
    }

    onDonwloadSellerMonthlyReport(): Observable<any> {
        this.ensureConnected();
        return this.downloadnSellerMonthly$;
    }

    onDownloadProductInfoReport():Observable<any> {
        this.ensureConnected();
        return this.downloadProductInfoReport$;
    }

    on(key : string): Observable<any>{
        this.ensureConnected();
        this.hubConnection.on(key, (data : StockAndProductPriceInformationDto) => {
            this.stockInformationSubject.next(data);
        });    
        return this.stockListInformation$;
    }
}
