import { NavService } from './services/nav.service';
import { StockService } from './services/stock.service';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'material.module';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from './pipes/localize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import {TruncateDecimalPipe} from './pipes/truncate-decimal.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import {CdkDetailRowDirective} from './directives/cdk-detail-row.directive'

import { UpdateComponent } from './components/update/update.component';
import { ConfirmGuard } from './auth/confirm.guard';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { DialogService } from './services/dialog.service';
import { StockMenuComponent } from './components/menu-btn-sheet/stock-menu/stock-menu.component';
import { StockTransferMenuComponent } from './components/menu-btn-sheet/stock-transfer-menu/stock-transfer-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuBtnSheetComponent } from './components/menu-btn-sheet/menu-btn-sheet.component';
import { PrincipalButtonComponent } from './components/buttons/principal-button.component';
import { SecundaryButtonComponent } from './components/buttons/secundary-button.component';
import { ActionButtonComponent } from './components/buttons/action-button.component';
import { StockMinstockMenuComponent } from './components/menu-btn-sheet/stock-minstock-menu/stock-minstock-menu.component';
import { SearchComponent } from './components/search/search.component';
import {SearchProductsComponent} from './components/search-products/search-products.component';
import {MultiSelectAutocompleteProductsComponent} from './components/multiselect-autocomplete-products/multiselect-autocomplete-products.component';
import { GatewayPaymentOrderService } from './services/gateway-payment-order.service';
import { ShortcutInfoComponent } from './components/shortcut-info/shortcut-info.component';
import { ActionMobileButtonComponent } from './components/action-mobile-button/action-mobile-button.component';
import { CloseBtnModalComponent } from './components/close-btn-modal/close-btn-modal.component';
import { DownloadPdfInvoiceService } from './services/donwload-pdf-invoice.service';
import {NotificationService} from './services/notification.service';
import {PrinterService} from './services/printer.service';
import { InfiniteScrollTableComponent} from './components/infinite-scroll-table/infinite-scroll-table.component';

declare global
{
    interface Number
    {
        toNumber(decimals : number): number;
    }
}
Number.prototype.toNumber = function(decimals : number): number
{
    return parseFloat(this.toFixed(decimals));
};


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        TruncatePipe,
        TruncateDecimalPipe,
        BusyDirective,
        AutofocusDirective,
        EqualValidator,
        UpdateComponent,
        MenuBtnSheetComponent,
        PrincipalButtonComponent,
        SecundaryButtonComponent,
        ActionButtonComponent,
        ConfirmComponent,
        StockMenuComponent,
        StockTransferMenuComponent,
        CdkDetailRowDirective,
        StockMinstockMenuComponent,
        SearchComponent,
        SearchProductsComponent,
        MultiSelectAutocompleteProductsComponent,
        ShortcutInfoComponent,
        ActionMobileButtonComponent,
        CloseBtnModalComponent,
        InfiniteScrollTableComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        TruncatePipe,
        TruncateDecimalPipe,
        BusyDirective,
        AutofocusDirective,
        EqualValidator,
        UpdateComponent,
        CdkDetailRowDirective,
        PrincipalButtonComponent,
        SecundaryButtonComponent,
        ActionButtonComponent,
        SearchComponent,
        SearchProductsComponent,
        MultiSelectAutocompleteProductsComponent,
        ActionMobileButtonComponent,
        CloseBtnModalComponent,
        InfiniteScrollTableComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                ConfirmGuard,
                LayoutStoreService,
                NavService,
                TruncatePipe,
                DialogService,
                StockService,
                GatewayPaymentOrderService,
                DownloadPdfInvoiceService,
                NotificationService,
                PrinterService
            ]
        };
    }
}
